<template>
  <menu class="menu" v-show="$route.path != '/'">
    <img
      src="../assets/filtro.svg"
      alt="Filtrar"
      @click="$store.commit('toggleFiltro')"
    />
    <SwitchMode />
    <img src="../assets/power.svg" alt="Encender/Apagar" />
  </menu>
</template>

<script>
import SwitchMode from "./SwitchMode.vue";

export default {
  components: {
    SwitchMode,
  },
  computed: {
    font: function () {
      return this.$store.getters.getFont;
    },
    fontAccent: function () {
      return this.$store.getters.getFontAccent;
    },
    color: function () {
      return this.$store.getters.getColor;
    },
    colorAccent: function () {
      return this.$store.getters.getColorAccent;
    },
    accent: function () {
      return this.$store.getters.getAccent;
    },
  },
};
</script>

<style lang="scss">
.menu {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: v-bind(color);
  height: 70px;
  width: 100%;
  border-top: 1px solid v-bind(accent);
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 0;
}

@media (min-width: 1080px) {
  .menu {
    display: none;
  }
}
</style>
