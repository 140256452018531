<template>
  <Header />
  <router-view />
  <Footer />
  <FiltroAvanzado v-show="$store.getters.showFiltro" />
  <SeleccionarFecha v-show="$store.getters.showFiltroFecha" />
  <SeleccionarSucursal v-show="$store.getters.showFiltroSucursal" />
  <SeleccionarDispositivo v-show="$store.getters.showFiltroDispositivo" />
  <SeleccionarUnidad v-show="$store.getters.showFiltroUnidad" />
  <div></div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import FiltroAvanzado from "@/components/FiltroAvanzado.vue";

import SeleccionarFecha from "@/components/SeleccionarFecha.vue";
import SeleccionarSucursal from "@/components/SeleccionarSucursal.vue";
import SeleccionarDispositivo from "@/components/SeleccionarDispositivo.vue";
import SeleccionarUnidad from "@/components/SeleccionarUnidad.vue";

export default {
  data() {
    return {};
  },
  computed: {
    font: function () {
      return this.$store.getters.getFont;
    },
    fontAccent: function () {
      return this.$store.getters.getFontAccent;
    },
    color: function () {
      return this.$store.getters.getColor;
    },
    colorAccent: function () {
      return this.$store.getters.getColorAccent;
    },
    accent: function () {
      return this.$store.getters.getAccent;
    },
  },
  components: {
    Header,
    Footer,
    FiltroAvanzado,

    SeleccionarFecha,
    SeleccionarSucursal,
    SeleccionarDispositivo,
    SeleccionarUnidad,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap");

#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

html,
#app,
h1,
h2,
h3,
h4,
h5,
a,
p,
input[type="datetime-local"] .btn {
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: v-bind(font);
}

body {
  margin: 0;
}

.bg {
  background: url("./assets/Background.png");
  background-color: v-bind(color);
  min-height: 100vh;
  margin: 0;
}

.btn {
  border-radius: 10px;
  background-color: #ffa300;
  color: #3c3733;
  box-shadow: inset 2.4px 2.8px 2.8px 0 #ffd180, 1px 4px 4px 0 #363636;
  text-align: center;
  font-weight: 700;
  font-size: 22px;
  border: none;
  cursor: pointer;
}
.btn--dark {
  border-radius: 10px;
  background-color: #5e5e5e;
  color: #fff;
  box-shadow: inset 2.4px 2.8px 2.8px 0 #fff, 1px 4px 4px 0 #5e5e5e;
  text-align: center;
  font-weight: 700;
  font-size: 22px;
  border: none;
  cursor: pointer;
}

.box {
  background: v-bind(color);
  box-shadow: none;
  border-radius: none;
}

@media (min-width: 1080px) {
  .box {
    background: v-bind(color);
    box-shadow: 10px 10px 10px v-bind(accent),
      -7px -6px 10px v-bind(colorAccent);
    border-radius: 10px;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
}
</style>
