<template>
  <div class="login">
    <img class="login__img" :src="'./' + img" alt="Logo flowzen" />
    <div class="form">
      <label class="form__label" for="correo">Correo electrónico</label>
      <input class="form__input" name="correo" type="text" />
      <label class="form__label" for="contrasena">Contraseña</label>
      <input class="form__input" type="password" />
      <router-link class="btn form__ingresar" to="/tablero"
        >Ingresar</router-link
      >
      <router-link class="form__olvide" to="/"
        >¿Olvidaste tu contraseña?</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    img: function () {
      return !this.mode ? "FlowzenLogoWhite.svg" : "FlowzenLogoBlack.svg";
    },
    mode: function () {
      return this.$store.getters.getTheme;
    },
    font: function () {
      return this.$store.getters.getFont;
    },
    fontAccent: function () {
      return this.$store.getters.getFontAccent;
    },
    color: function () {
      return this.$store.getters.getColor;
    },
    colorAccent: function () {
      return this.$store.getters.getColorAccent;
    },
    accent: function () {
      return this.$store.getters.getAccent;
    },
  },
};
</script>

<style scoped>
.login {
  max-width: 380px;
  width: 90%;
  margin: 0 auto;
  background-color: v-bind(color);
  border-radius: 25px;
  border: 11px solid v-bind(accent);
  box-shadow: inset 1px 1px 10px 0 v-bind(color), -4px 0 10px 0 v-bind(accent);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login__img {
  text-align: center;
  margin: 37px auto 22px auto;
  width: 200px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
}

.form__label {
  height: 18px;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 8px;
  color: v-bind(fontAccent);
}
.form__input {
  height: 40px;
  width: 100%;
  opacity: 0.5;
  border-radius: 25px;
  color: v-bind(fontAccent);
  background-color: v-bind(color);
  box-shadow: inset -4px -4px 5px 0 v-bind(accent), inset 4px 4px 5px 0 #484848;
  margin-bottom: 16px;
  padding-left: 10px;
}

.form__ingresar {
  width: 100%;
  padding: 10px 0;
  font-size: 22px;
  color: #3c3733;
}

.form__olvide {
  margin: 80px auto;
  color: #af5135;
  font-size: 16px;
  line-height: 20px;
  align-self: center;
}
</style>
