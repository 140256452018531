<template>
  <div class="filtrar">
    <header class="filtrar__header">
      <p class="filtrar__header__title">Seleccione una fecha</p>
      <img
        class="filtrar__header__x"
        src="../assets/x.svg"
        alt="Cerrar"
        @click="$store.commit('toggleFiltroFecha')"
      />
    </header>
    <div class="filtrar__body">
      <div class="filtrar__fechas">
        <label for="inicial">Fecha Inicial</label>
        <input v-model="fechaInicial" name="inicial" type="datetime-local" />
        <label for="final">Fecha Final</label>
        <input v-model="fechaFinal" name="final" type="datetime-local" />
        <p>Rango de fechas seleccionadas:</p>
        <p>{{ fechaInicial }}</p>
        <p>{{ fechaFinal }}</p>
      </div>

      <ul class="filtrar__opciones">
        <li class="filtrar__opciones__opcion">Personalizado</li>
        <li class="filtrar__opciones__opcion">Hoy</li>
        <li class="filtrar__opciones__opcion">Ayer</li>
        <li class="filtrar__opciones__opcion">Esta semana</li>
        <li class="filtrar__opciones__opcion">La semana pasada</li>
        <li class="filtrar__opciones__opcion">Este mes</li>
        <li class="filtrar__opciones__opcion">El mes pasado</li>
        <li class="filtrar__opciones__opcion">Este trimestre</li>
      </ul>

      <div class="filtrar__btns">
        <button class="filtrar__btn btn">Seleccionar</button>
        <button
          class="filtrar__btn btn--dark"
          @click="$store.commit('toggleFiltroFecha')"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fechaInicial: null,
      fechaFinal: null,
    };
  },
  methods: {},
  computed: {
    font: function () {
      return this.$store.getters.getFont;
    },
    fontAccent: function () {
      return this.$store.getters.getFontAccent;
    },
    color: function () {
      return this.$store.getters.getColor;
    },
    colorAccent: function () {
      return this.$store.getters.getColorAccent;
    },
    accent: function () {
      return this.$store.getters.getAccent;
    },
  },
};
</script>

<style lang="scss" scoped>
.filtrar {
  z-index: 100;
  width: 95%;
  height: 750px;
  margin: 0 auto;
  border: 1px solid v-bind(accent);
  border-radius: 20px;
  background-color: v-bind(accent);
  position: fixed;
  top: 95px;
  left: 0;
  right: 0;

  box-shadow: 0px 0px 132px 13px #333333;
  -webkit-box-shadow: 0px 0px 132px 13px #333333;
  -moz-box-shadow: 0px 0px 132px 13px #333333;

  &__header {
    background-color: v-bind(colorAccent);
    height: 58px;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16.5px;

    &__title {
      margin: 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }

    &__x {
      width: 11px;
      height: 11px;
    }
  }

  &__body {
    background-color: v-bind(color);
    border-radius: 0 0 20px 20px;
    height: 692px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  &__fechas {
    background-color: v-bind(colorAccent);
    color: v-bind(fontAccent);
    width: 85%;
    margin: 0 auto;
    padding: 10px 0;
    border: 11px solid v-bind(color);
    box-shadow: 6px 6px 10px v-bind(colorAccent), -4px -2px 4px v-bind(accent),
      inset 1px 1px 12.048px rgba(0, 0, 0, 0.5);
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    gap: 5px;

    p,
    input,
    label {
      width: 90%;
      margin: 0 auto;
      color: v-bind(font);
    }

    input {
      background-color: transparent;
      border: none;
    }
  }

  &__opciones {
    list-style-type: none;
    padding: 0;
    width: 90%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 9.5px;

    &__opcion {
      height: 35px;
      background-color: v-bind(colorAccent);
      color: v-bind(font);
      font-size: 16px;
      line-height: 20px;
      border-radius: 25px;
      border: 1px solid v-bind(accent);
      padding-left: 22px;
      box-shadow: inset -4px -4px 12.048px v-bind(color),
        inset 4px 4px 6.024px rgba($color: #484848, $alpha: 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }
  }

  &__btns {
    width: 85%;
    margin: 0 auto;
    display: flex;
    gap: 13px;
  }

  &__btn {
    width: 100%;
    height: 42px;
    font-size: 18px;
  }
}

@media (min-width: 1080px) {
  .filtrar {
    max-width: 890px;

    &__body {
      padding-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &__fechas {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
    &__opciones {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    &__btns {
      grid-column: 2 / 3;
    }
  }
}
</style>
