<template>
  <header class="header" v-show="$route.path != '/'">
    <img class="header__logo" :src="'./' + img" alt="logo flowzen" />
    <img
      class="header__burger"
      src="../assets/burger.svg"
      alt="Menu hamburguesa"
      @click="burger = !burger"
    />
  </header>
  <menu class="burgermenu" v-show="burger">
    <img
      class="burgermenu__x"
      src="../assets/x.svg"
      alt="Menu hamburguesa"
      @click="burger = !burger"
    />
    <nav class="burgermenu__nav">
      <span
        class="burgermenu__nav__link"
        @click="
          $store.commit('toggleFiltro');
          burger = !burger;
        "
      >
        Filtrar
      </span>
      <router-link
        class="burgermenu__nav__link"
        to="/tablero"
        @click="burger = !burger"
      >
        Informes
      </router-link>
      <span
        class="burgermenu__nav__link"
        @click="
          $store.commit('toggleMode');
          burger = !burger;
        "
      >
        Cambiar tema
      </span>
      <router-link
        class="burgermenu__nav__link"
        to="/"
        @click="burger = !burger"
      >
        Cerrar sesión
      </router-link>
    </nav>
  </menu>
</template>

<script>
export default {
  data() {
    return {
      burger: false,
    };
  },
  computed: {
    img: function () {
      return !this.mode ? "FlowzenLogoWhite.svg" : "FlowzenLogoBlack.svg";
    },
    mode: function () {
      return this.$store.getters.getTheme;
    },
    font: function () {
      return this.$store.getters.getFont;
    },
    fontAccent: function () {
      return this.$store.getters.getFontAccent;
    },
    color: function () {
      return this.$store.getters.getColor;
    },
    colorAccent: function () {
      return this.$store.getters.getColorAccent;
    },
    accent: function () {
      return this.$store.getters.getAccent;
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: v-bind(color);
  height: 70px;
  padding: 0 35px;
  border-bottom: 1px solid v-bind(accent);
  border-radius: 0 0 5px 5px;

  &__logo {
    width: 116px;
    height: 41px;
  }

  &__burger {
    width: 32px;
  }
}

.burgermenu {
  z-index: 100;
  position: fixed;
  margin: 0;
  top: 0;
  right: 0;
  width: 80%;
  height: 900px;
  background-color: v-bind(color);
  border-radius: 20px 0 0 20px;

  box-shadow: 0px 0px 132px 13px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 132px 13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 132px 13px rgba(0, 0, 0, 0.75);

  &__x {
    position: fixed;
    top: 24px;
    right: 24px;
    height: 24px;
    width: 24px;
  }

  &__nav {
    width: 60%;
    position: fixed;
    top: 45px;
    left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    &__link {
      width: 100%;
      padding: 25px 0;
      color: v-bind(fontAccent);

      &:not(:last-child) {
        border-bottom: 2px solid #c8c8c8;
      }
    }
  }
}

@media (min-width: 1080px) {
  .header {
    display: none;
  }
}
</style>
