<template>
  <main class="main bg">
    <Flowzen />
    <LogIn />
    <p class="main__desarrollado">
      Desarrollador por <span>hg consulting</span>
    </p>
  </main>
</template>

<script>
// @ is an alias to /src
import Flowzen from "@/components/Flowzen.vue";
import LogIn from "../components/LogIn.vue";

export default {
  name: "Home",
  components: {
    LogIn,
    Flowzen,
  },
  computed: {
    font: function () {
      return this.$store.getters.getFont;
    },
    fontAccent: function () {
      return this.$store.getters.getFontAccent;
    },
    color: function () {
      return this.$store.getters.getColor;
    },
    colorAccent: function () {
      return this.$store.getters.getColorAccent;
    },
    accent: function () {
      return this.$store.getters.getAccent;
    },
  },
};
</script>

<style scoped>
.main {
  /* margin-top: 46px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 84px;
}

.main__desarrollado {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.main__desarrollado span {
  text-transform: uppercase;
  color: #af5135;
}
</style>
