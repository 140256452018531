import { createStore } from "vuex";

export default createStore({
  state: {
    mode: true,
    filtrar: false,
    filtrarFecha: false,
    filtrarSucursal: false,
    filtrarDispositivo: false,
    filtrarUnidad: false,
  },
  mutations: {
    toggleFiltro(state) {
      state.filtrar = !state.filtrar;
    },
    toggleFiltroFecha(state) {
      state.filtrarFecha = !state.filtrarFecha;
    },
    toggleFiltroSucursal(state) {
      state.filtrarSucursal = !state.filtrarSucursal;
    },
    toggleFiltroDispositivo(state) {
      state.filtrarDispositivo = !state.filtrarDispositivo;
    },
    toggleFiltroUnidad(state) {
      state.filtrarUnidad = !state.filtrarUnidad;
    },
    toggleMode(state) {
      state.mode = !state.mode;
    },
  },
  actions: {},
  modules: {},
  getters: {
    getTheme(state) {
      return state.mode;
    },
    getFontAccent(state) {
      return state.mode ? "#333333" : "#fff";
    },
    getFont(state) {
      return state.mode ? "#6c6c6c" : "#c2c2c2";
    },
    getColorAccent(state) {
      return state.mode ? "#d5eaff" : "#323232";
    },
    getColor(state) {
      return state.mode ? "#e3eef8" : "#494a4a";
    },
    getAccent(state) {
      return state.mode ? "#fff" : "#333";
    },
    showFiltro(state) {
      return state.filtrar;
    },
    showFiltroFecha(state) {
      return state.filtrarFecha;
    },
    showFiltroSucursal(state) {
      return state.filtrarSucursal;
    },
    showFiltroDispositivo(state) {
      return state.filtrarDispositivo;
    },
    showFiltroUnidad(state) {
      return state.filtrarUnidad;
    },
  },
});
