<template>
  <div class="filtro">
    <div class="filtro__opciones">
      <p class="filtro__opcion" @click="$store.commit('toggleFiltroFecha')">
        Seleccione una fecha
      </p>
      <p class="filtro__opcion" @click="$store.commit('toggleFiltroSucursal')">
        Elige una sucursal
      </p>
      <p
        class="filtro__opcion"
        @click="$store.commit('toggleFiltroDispositivo')"
      >
        No. de dispositivo
      </p>
      <p class="filtro__opcion" @click="$store.commit('toggleFiltroUnidad')">
        Unidad de medida
      </p>
    </div>
    <div class="filtro__btns">
      <button class="filtro__btn btn">Seleccionar</button>
      <button
        class="filtro__btn btn--dark"
        @click="$store.commit('toggleFiltro')"
      >
        Cerrar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    font: function () {
      return this.$store.getters.getFont;
    },
    fontAccent: function () {
      return this.$store.getters.getFontAccent;
    },
    color: function () {
      return this.$store.getters.getColor;
    },
    colorAccent: function () {
      return this.$store.getters.getColorAccent;
    },
    accent: function () {
      return this.$store.getters.getAccent;
    },
  },
};
</script>

<style lang="scss" scoped>
.filtro {
  width: 95%;
  margin: 0 auto;
  border-radius: 20px;
  border: 1px solid v-bind(accent);
  background-color: v-bind(color);
  position: fixed;
  top: 120px;
  left: 0;
  right: 0;

  box-shadow: 0px 0px 132px 13px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 132px 13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 132px 13px rgba(0, 0, 0, 0.75);

  &__opciones {
    width: 85%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__opcion {
    font-size: 20px;
    color: v-bind(fontAccent);
    margin: 0;
    cursor: pointer;

    &:not(:last-child) {
      padding-bottom: 17px;
      border-bottom: 2px solid #c8c8c8;
    }
  }

  &__btns {
    width: 85%;
    margin: 35px auto;
    display: flex;
    gap: 13px;
  }

  &__btn {
    width: 100%;
    height: 42px;
    font-size: 18px;
  }
}

@media (min-width: 1080px) {
  .filtro {
    max-width: 360px;
  }
}
</style>
