<template>
  <div class="modo">
    <img
      class="modo__img"
      :class="mode ? 'selected' : ''"
      src="../assets/sun.svg"
      alt="Modo claro"
      @click="$store.commit('toggleMode')"
    />
    <img
      class="modo__img"
      :class="!mode ? 'selected' : ''"
      src="../assets/moon.svg"
      alt="Modo oscuro"
      @click="$store.commit('toggleMode')"
    />
  </div>
</template>

<script>
export default {
  computed: {
    font: function () {
      return this.$store.getters.getFont;
    },
    fontAccent: function () {
      return this.$store.getters.getFontAccent;
    },
    color: function () {
      return this.$store.getters.getColor;
    },
    colorAccent: function () {
      return this.$store.getters.getColorAccent;
    },
    accent: function () {
      return this.$store.getters.getAccent;
    },
    mode: function () {
      return this.$store.getters.getTheme;
    },
  },
};
</script>

<style lang="scss" scoped>
.modo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;

  &__img {
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
  }
}

.selected {
  box-shadow: inset -4px 1px 3.614px v-bind(colorAccent),
    inset 0px 0px 4.82px v-bind(accent);
}
</style>
