<template>
    <div class="form-group">
        <label for="avatar" class="control-label">Avatar</label>
        <input type="file" v-on:change="fileChange" id="avatar">
        <div class="help-block">
            Help block here updated 4 🍸 ...
        </div>
    </div>

    <div class="col-md-6">
        <input type="hidden" name="avatar_id">
        <img class="avatar" title="Current avatar">
    </div>
</template>

<script>
export default{
    methods: {
        fileChange(){
            console.log('Test of file input change')
        }
    }
}
</script>